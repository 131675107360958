import React from 'react'
import { PageProps } from 'gatsby'
import './page.css'

const Page = ({ pageContext }: PageProps): React.ReactElement => {
  console.log(pageContext.node.markdown)

  return (
    <main className="page">
      <h2 className="page-title">{pageContext.node.title}</h2>
      <div
        dangerouslySetInnerHTML={{
          __html: pageContext.node.rich_text_editor,
        }}
      ></div>
      {pageContext.node.markdown && (
        <div
          dangerouslySetInnerHTML={{ __html: pageContext.node.markdown }}
        ></div>
      )}
    </main>
  )
}

export default Page
